import { useLayoutEffect, useState } from "react";
import { debounce } from "lodash";

interface WindowSize {
  width: number;
  height: number;
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleSize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Set size at the first client-side load
  useLayoutEffect(() => {
    const debounceTimeout = 50;
    const debouncedResize = debounce(handleSize, debounceTimeout);
    window.addEventListener("resize", debouncedResize);
    return () => {
      debouncedResize.cancel();
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
