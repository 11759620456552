import { useState, useEffect, useRef, PointerEvent, useCallback } from "react";
import gsap from "gsap";
import bg from "./assets/bg.svg";
import logo from "./assets/logo.svg";
import wheelBg from "./assets/wheel-bg.svg";
import indicator from "./assets/indicator.svg";
import shadow from "./assets/shadow.png";
import useWindowSize from "./hooks/useWindowSize";
import Loader from "./Loader";
import "./scss/app.scss";

declare let Winwheel: any;
window.gsap = gsap;

function App() {
  const IS_DEV = false;
  const API_ENDPOINT = IS_DEV
    ? "http://localhost:8080"
    : "https://pepco.digitaljobs.gr/backend";
  const startPointRef = useRef<PointerEvent | null>(null);

  const wheel = useRef<any>(null);
  const wheelheight = 1100;
  const wheelWidth = 800;
  const totalSpins = 3;

  const [loading, setLoading] = useState(false);
  const [remaining, setRemaining] = useState(totalSpins);
  const tapRef = useRef(0);
  const remainingRef = useRef(totalSpins);
  const idRef = useRef(0);
  const [spinning, setSpinning] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(true);
  const { height, width } = useWindowSize();
  const wheelFactor = height / wheelheight;
  const blockPortrait = height > width;
  const allSections = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const winSections = [4, 7, 9, 12];

  function resetWheel() {
    wheel.current.rotationAngle = 0;
    wheel.current.draw();
  }
  function calculateSession(): number[] {
    const temp: number[] = [];
    for (let i = 0; i < totalSpins; i++) {
      temp.push(allSections[Math.floor(Math.random() * allSections.length)]);
    }
    console.log(temp);
    return temp;
  }
  const sessionTriesRef = useRef<number[]>([]);
  /*
  function calculatePrize() {
    setSpinning(true);

    resetWheel();
    const arr = allSections;
    const stopAt = wheel.current.getRandomForSegment(
      allSections[Math.floor(Math.random() * allSections.length)]
    );
    wheel.current.animation.stopAngle = stopAt;
    wheel.current.startAnimation();
  }
  */

  function getSessionTries() {
    setLoading(true);
    fetch(`${API_ENDPOINT}/draw.php`)
      .then((response) => response.json())
      .then((data) => {
        sessionTriesRef.current = data.sections;
        idRef.current = data.id;

        setLoading(false);
      })
      .catch(() => {
        alert("ERROR");
      });
  }

  const spin = () => {
    setSpinning(true);

    resetWheel();
    const stopAt = wheel.current.getRandomForSegment(
      sessionTriesRef.current[totalSpins - remainingRef.current]
    );
    wheel.current.animation.stopAngle = stopAt;
    wheel.current.startAnimation();
  };

  const distance = (p1: PointerEvent, p2: PointerEvent): number => {
    let dx = p2.clientX - p1.clientX;
    let dy = p2.clientY - p1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };
  const pointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (spinning) {
        return;
      }
      if (startPointRef.current === null) {
        startPointRef.current = e;
      }
    },
    [spinning]
  );
  const pointerUp = useCallback(() => {
    startPointRef.current = null;
  }, []);
  const pointerMove = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      if (startPointRef.current !== null) {
        const dist = distance(startPointRef.current, e);
        if (dist > (width / 1920) * 200) {
          pointerUp();
          spin();
        }
      }
    },
    [pointerUp, width]
  );
  /*
  useEffect(() => {
    const imgArray: string[] = [];
    for (let i = 0; i < 12; i++) {
      imgArray.push(`${process.env.PUBLIC_URL}images/section${i + 1}.png`);
    }
    Promise.allSettled(imgArray.map((url) => fetch(url)))
      .then(() => {
        setAssetsLoaded(true);
      })
      .catch(() => {});
  }, []);
*/
  /*
  useEffect(() => {
    if (!assetsLoaded) {
      const imgArray: string[] = [];
      for (let i = 0; i < 12; i++) {
        imgArray.push(`${process.env.PUBLIC_URL}images/section${i + 1}.png`);
      }
      Promise.allSettled(imgArray.map((url) => fetch(url)))
        .then(() => {
          setAssetsLoaded(true);
        })
        .catch(() => {});
    }
  }, [assetsLoaded]);
*/
  const resetSession = () => {
    resetWheel();
    getSessionTries();
    setRemaining(totalSpins);
    remainingRef.current = totalSpins;
  };

  useEffect(() => {
    function animFinished() {
      setSpinning(false);
      if (remainingRef.current >= 1) {
        const cur = sessionTriesRef.current[totalSpins - remainingRef.current];

        if (winSections.includes(cur)) {
          fetch(`${API_ENDPOINT}/update.php`, {
            method: "POST",
            body: JSON.stringify({
              id: idRef.current,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              resetSession();
              alert("ΣΥΓΧΑΡΗΤΗΡΙΑ!");
            })
            .catch(() => {
              alert("ERROR");
            });

          return;
        }
        if (remainingRef.current > 1) {
          setRemaining((prev) => prev - 1);

          remainingRef.current -= 1;
        } else {
          alert("ΤΗΝ ΕΠΟΜΕΝΗ ΦΟΡΑ!");
          resetSession();
        }
      }
    }

    let tim: number;
    if (wheel.current === null && assetsLoaded) {
      getSessionTries();
      wheel.current = new Winwheel({
        numSegments: 12,
        outerRadius: 361.5,
        drawMode: "segmentImage",
        segments: [
          {
            image: `${process.env.PUBLIC_URL}images/section1.png`,
            text: "",
          },
          { image: `${process.env.PUBLIC_URL}images/section2.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section3.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section4.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section5.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section6.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section7.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section8.png`, text: "" },
          { image: `${process.env.PUBLIC_URL}images/section9.png`, text: "" },
          {
            image: `${process.env.PUBLIC_URL}images/section10.png`,
            text: "",
          },
          {
            image: `${process.env.PUBLIC_URL}images/section11.png`,
            text: "",
          },
          {
            image: `${process.env.PUBLIC_URL}images/section12.png`,
            text: "",
          },
        ],

        animation: {
          type: "spinToStop",
          duration: 4,
          spins: 3,
          callbackFinished: animFinished,
        },
      });

      /*tim = window.setTimeout(() => {
        resetWheel();
        console.log("resetting");
      }, 200);*/
    }
    return () => {
      if (tim) {
        window.clearTimeout(tim);
      }
    };
  }, [assetsLoaded]);

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${bg})`,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div className="logo">
        <img
          src={logo}
          alt="pepco"
          onTouchStart={(e) => {
            e.preventDefault();
            var now = new Date().getTime();
            var timesince = now - tapRef.current;
            if (timesince < 600 && timesince > 0) {
              // double tap
              window.location.reload();
            } else {
              // too much time to be a doubletap
            }

            tapRef.current = new Date().getTime();
          }}
        />
      </div>
      <div
        className="wheel-container"
        style={{
          backgroundImage: `url(${wheelBg})`,
          transform: `translateX(-50%) scale(${wheelFactor})`,
          display: `${blockPortrait ? "none" : "block"}`,
        }}
      >
        <div
          className="canvas-container"
          onPointerDown={(e) => pointerDown(e)}
          onPointerMove={(e) => pointerMove(e)}
          onPointerUp={(e) => pointerUp()}
          onPointerLeave={(e) => pointerUp()}
          onPointerCancel={(e) => pointerUp()}
        >
          <canvas id="canvas" width="723" height="723"></canvas>
          <img src={shadow} alt="" className="shadow" />
          <img src={indicator} alt="" className="indicator" />
        </div>
      </div>

      <div
        className="text-container"
        style={{
          transform: `scale(${wheelFactor * 0.9})`,
          display: `${blockPortrait ? "none" : "block"}`,
        }}
      >
        <div className="big">
          Γυρισε τον τροχο
          <br />& δες αν κερδισες
        </div>
        <div className="small">
          Έχεις {remaining} {remaining !== 1 ? "προσπάθειες" : "προσπάθεια"}!
        </div>
      </div>
      <div
        className="block-container"
        style={{
          transform: `scale(${wheelFactor * 0.9}) translateX(-50%)`,
          display: `${blockPortrait ? "block" : "none"}`,
        }}
      >
        <div className="big">Γυρνα τη συσκευη σου οριζοντια</div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
}

export default App;
